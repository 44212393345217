<template>
  <div class="mr-6">
    <v-expansion-panels elevation="0" class="hb-expansion-panel" v-model="setupModal">
      <hb-expansion-panel v-for="(workflow, i) in workflows" :key="i" :left-cols="4" :right-cols="8">
        <template v-slot:title>{{ formatWorkflowName(workflow.automation_group_name) }}</template>
        <template v-slot:actions>
          <hb-tooltip v-if="workflow.last_modified_by || workflow.last_modified_at">
            <template v-slot:item>
              <HbIcon mdi-code="mdi-account-clock-outline" class="mx-2" small />
            </template>
            <template v-slot:header>
              {{ AUTOMATION.LAST_MODIFIED }}
            </template>
            <template v-slot:body>
              <div>{{ workflow.last_modified_by }}</div>
              <div>{{ workflow.last_modified_at | formatLocalDateTimeServices }}</div>
            </template>
          </hb-tooltip>
          <hb-switch class="mx-2" v-model="workflow.assigned"
            @click.native.stop="(event) => toggleStatus(event, workflow)" :readonly="true" />
        </template>
        <template v-slot:content>
          <div class="mx-6 mb-6">
            <AutomationSubAccordian :automation_types="workflow.automation_subcategories" :template="template"
              :selectedPropertyId="selectedPropertyId" :selectedAutomationType="selectedAutomationType"
              :parent_automation_group_id="workflow.automation_group_id" />
          </div>
        </template>
      </hb-expansion-panel>
    </v-expansion-panels>
    <workflow-toggle v-model="showTypesToggleModal" :workflow="currentAutomation" :template="template" :selectedAutomationType="selectedAutomationType" :disabled="showLoader"
      v-if="showTypesToggleModal" @close="confirmStatus()" @ok="confirmStatus(true)" />
  </div>
</template>
<script type="text/babel">
import { mapGetters, mapActions } from "vuex";
import WorkflowToggle from './WorkflowToggleModal.vue';
import AutomationSubAccordian from './AutomationSubAccordian.vue'
import AUTOMATION from '@/constants/automation.js';
import ADDRESS from '@/constants/address.js';
import { countryStateCityMixin } from '@/mixins/countryStateCityMixin.js';
import api from "@/assets/api.js";

export default {
  name: "AutomationsAccordian",
  data() {
    return {
      setupModal: [],
      setupModal_1: [],
      isTimelineLoading: false,
      timeline: [],
      showTypesToggleModal: false,
      currentAutomation: {},
      showLoader: false
    };
  },
  mixins: [countryStateCityMixin],
  components: {
    WorkflowToggle,
    AutomationSubAccordian
  },
  props: {
    workflows: {
      type: Array,
      default() {
        return [];
      }
    },
    template: {
      type: String,
      default() {
        return 'corporate';
      }
    },
    selectedAutomationType: {
      type: Object,
      default: () => ({})
    },
    selectedPropertyId: {
      type: String || null,
      default() {
        return null;
      }
    },
  },
  created() {
    this.AUTOMATION = AUTOMATION;
    this.setCountiesAndStates();
  },
  computed: {
    ...mapGetters({
      currentTimeline: "automationStore/getCurrentTimeLine",
    }),
  },
  methods: {
    ...mapActions({
      fetchAllWorkflows: 'automationStore/fetchAllWorkflows',
      setNotification: 'automationStore/setNotification'
    }),
    toggleStatus(e, automation) {
      e.preventDefault();
      this.currentAutomation = { ...automation };
      this.showTypesToggleModal = true;
    },
    async confirmStatus(confirm = false) {
      if (confirm) {
        this.showLoader = true;
        let unit_type_names = this.currentAutomation.automation_subcategories.map(node => node.name.toLowerCase())
        let automation_group_id = this.currentAutomation.automation_subcategories.map(node => node.id)
        let params = {
          unit_types: unit_type_names,
          property_id: this.selectedPropertyId,
          automation_group_ids: automation_group_id,
          is_parent_node_modified: true,
          parent_automation_group_id: this.currentAutomation.automation_group_id
        };

        let endPointName = this.currentAutomation.assigned ? `pause` : `resume`;
        try {
          let response = await api.put(this, `${api.AUTOMATION}/${this.selectedAutomationType.id}/${endPointName}`, params);
          if (response) {
            await this.fetchAllWorkflows({
              automation_type_id: this.selectedAutomationType.id,
              property_id: this.selectedPropertyId
            });
          }
          this.showLoader = false;
          this.showTypesToggleModal = false;
          this.setNotification({type:'success', msg: `Automation has been successfully ${this.currentAutomation.assigned ? 'Paused.' : 'Resumed.'}`});
        } catch (err) {
          this.showLoader = false;
          this.setNotification({type:'error', msg: err});
        }

      } else {
        this.showTypesToggleModal = false;
      }
    },
    async getTimeline() {
      this.isTimelineLoading = true;
      this.timeline = this.currentTimeline;
      this.isTimelineLoading = false;
    },
    setCountiesAndStates() {
      this.setCountriesListEvent();
      this.setStatesListEvent(ADDRESS.DEFAULT_COUNTRY);
    },
    formatWorkflowName(workflow_name) {
      if (this.selectedAutomationType.name.toLowerCase() === AUTOMATION.DELINQUENCY_AUTOMATION.toLowerCase()) {
        // then replace the state_code with state_name.
        return this.stateListRawMixin.find(item => item.isoCode == workflow_name)?.name || workflow_name;
      }
      return workflow_name;
    }
  }
};
</script>
<style scoped>
.action-items {
  font-size: 14px;
}
</style>
