<template>
 <div class="pa-0 ma-0 mt-3 pr-6">
    <v-expansion-panels v-model="panel">
      <hb-expansion-panel v-for="(action, actionIndex) in missingActions" :key="actionIndex" class="mb-2" caution
        :leftCols="'8'" :rightCols="'4'">
        <template v-slot:title>
         <div> Warning: {{ action.type }}</div>
        </template>
        <template v-slot:content class="pa-10">
          <div class="pa-6">
            <ol>
              <li v-for="(detail, detailIndex) in action.details" :key="detailIndex">
                <div>{{ detail.property_number }} - {{ detail.property_name }}</div>

                <ul>
                  <li v-for="(actionItem, actionItemIndex) in detail.actions" :key="actionItemIndex">
                    <div v-if="action.type === 'Missing Documents with Delivery Method for Messages'">
                      <div>{{ actionItem.space_type_timeline }}</div>
                      <ul>
                        <li v-for="(document, documentIndex) in actionItem.documents" :key="documentIndex">
                          {{ document.document_name }} - {{ document.dm_names.join(', ') }}
                        </li>
                      </ul>
                    </div>
                    <div v-else>
                      {{ actionItem.space_type_timeline }} - {{ actionItem.names.join(', ') }}
                    </div>
                  </li>
                </ul>
              </li>
            </ol>
          </div>
        </template>
      </hb-expansion-panel>
    </v-expansion-panels>
  </div>
</template>
<script type="text/babel">
export default {
  name: "AutomationWarnings",
  data() {
    return {
      panel: true,
      setupModal: [],
    };
  },
  props: {
    missingActions:
    {
      type: Array,
      default() {
        return [];
      }
    }
  },
  components: {
  },
  computed: {

    missedActions() {
      return this.missingActions;
    }
  },
  created() {
  },
  destroyed() {
  },
  methods: {  
  }

};
</script>
  